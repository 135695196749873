import { Image as RNImage } from "@rneui/themed";

function Image({ name, uri, height, width, ...props }) {
  return (
    <RNImage
      containerStyle={{ height, width }}
      source={name ? require(`../../../assets/${name}`) : { uri }}
      resizeMode="contain"
      {...props}
    />
  );
}

export default Image;
