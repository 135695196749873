import React from "react";
import SelectDropdown from "react-native-select-dropdown";
import styled from "styled-components/native";
import Text from "./Text";
import { Input as RNInput } from "@rneui/themed";
import { MaterialIcons } from "@expo/vector-icons";

const SInput = styled(RNInput)`
  background-color: ${(props) => (props.editable ? "none" : "lightgray")};
  padding: 0 10px;
  width: 100%;
  color: #00205b;
  text-align: center;
`;

const StyledSelectDropdown = styled(SelectDropdown)`
  background-color: none;
  padding: 0 10px;
  width: 100%;
`;

const Label = styled(Text)`
  color: #00205b;
  text-align: center;
`;

const RequiredLabel = styled(Label)`
  color: #ab2328;
`;

const SearchIcon = styled(MaterialIcons).attrs({
  name: "search",
  size: 20,
  color: "#00205b",
  alignSelf: "center",
})`
  margin-left: 10px;
`;

function Input({
  label,
  required = false,
  dropdown = false,
  dropdownChoices,
  rowTextForSelection,
  selectedItem,
  onSelect,
  ...props
}) {
  return (
    <>
      {label && (
        <Label>
          {label} {required && <RequiredLabel>*</RequiredLabel>}
        </Label>
      )}
      {dropdown ? (
        <StyledSelectDropdown
          disabled={props.editable && props.editable == false}
          rowTextForSelection={(item, index) => rowTextForSelection(item)}
          defaultButtonText={props.value}
          selectedItem={props.value}
          data={dropdownChoices}
          onSelect={(value) => {
            console.log("value", value);
            console.log("value1", props.value);

            onSelect(value);
          }}
          // onSelect={(value) => setSelectedValue(value)}
          buttonTextAfterSelection={(item, index) => selectedItem(item)}
          buttonStyle={{
            backgroundColor: "none",
            fontFamily: "Barlow_400Regular",
            width: "98%",
            alignSelf: "center",
            alignContent: "flex-start",
            justifyContent: "center",
            color: "#00205b",
            borderBottomWidth: 0.1,
            paddingLeft: 1.5,
            borderColor: "#00205b",
          }}
          buttonTextStyle={{
            color: props.value ? "#00205b" : "#76787A",
            //

            fontFamily: "Barlow_400Regular",
            fontSize: 16,
            flexDirection: "row",
            textAlign: "left",
            alignText: "left",
            placeholderTextColor: "red",
          }}
          placeholderTextColor="#76787A"
          rowStyle={{ backgroundColor: "lightgray" }}
          rowTextStyle={{ color: "#00205b", fontFamily: "Barlow_400Regular" }}
          dropdownStyle={{
            backgroundColor: "lightgray",
            placeholderTextColor: "red",
          }}
          dropdownTextStyle={{
            color: "#00205b",
            fontFamily: "Barlow_400Regular",
          }}
          renderDropdownIcon={() => <SearchIcon />}
        />
      ) : (
        <SInput placeholderTextColor="#76787A" editable={true} {...props} />
      )}
    </>
  );
}

export default Input;
