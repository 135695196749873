import styled from 'styled-components/native';
import Message from './Message';
import FlashNotification from '../FlashNotification';

// const SuccessMessage = styled(Message)`
//   background-color: #d1ffd3;
//   color: green;
// `;
const SuccessMessage = (props) => <FlashNotification message="hello" />;

export default SuccessMessage;
