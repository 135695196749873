import { useStoreActions } from "easy-peasy";
import Button from "../../presentational/Button";
import styled from "styled-components/native";
// import useSound from "use-sound";

const Container = styled(Button)`
  background-color: #198754;
`;

function GetInvoicesButton({ children = "Sync Invoices", ...props }) {
  const { getInvoicesThunk } = useStoreActions((actions) => actions);
  // const [playSuccessSound] = useSound(
  //   "https://kioskbranding.blob.core.windows.net/sounds/successBell.mp3",
  //   {
  //     playbackRate: 0.5,
  //     volume: 1,
  //   }
  // );
  // const [playFailSound] = useSound(
  //   "https://kioskbranding.blob.core.windows.net/sounds/fail.mp3",
  //   { playbackRate: 0.5, volume: 1 }
  // );

  return (
    <Container onPress={() => getInvoicesThunk({})} {...props}>
      {children}
    </Container>
  );
}

export default GetInvoicesButton;
