import { useStoreState } from "easy-peasy";
import { StatusBar } from "expo-status-bar";
import Message from "../../presentational/Message";
import { ActivityIndicator, View } from "react-native";
import Center from "../../presentational/Center";
import { useState, useEffect } from "react";
import styled from "styled-components/native";

const SText = styled.Text`
  font-size: 30px;
  position: absolute;
  top: 35%;
`;

function Page({ children, ...props }) {
  const { alerts, loading } = useStoreState((state) => state.status);

  const [showMessage, setShowMessage] = useState(false);
  const [loadingStartTime, setLoadingStartTime] = useState(null);

  useEffect(() => {
    if (loading) {
      setLoadingStartTime(new Date());
    } else {
      setLoadingStartTime(null);
      setShowMessage(false);
    }
  }, [loading]);

  useEffect(() => {
    let timer;

    if (loadingStartTime) {
      timer = setTimeout(() => {
        setShowMessage(true);
      }, 5000);
    }

    return () => clearTimeout(timer);
  }, [loadingStartTime]);

  return (
    <View {...props}>
      <StatusBar style="auto" />

      {alerts.map(({ type, ...alert }) => {
        let Component = Message;
        if (type === "error") Component = Message.Error;
        else if (type === "success") Component = Message.Success;
        return <Component>{alert.message}</Component>;
      })}

      {loading ? (
        <Center.Screen>
          {showMessage && <SText>This could take a few moments...</SText>}
          <ActivityIndicator size="large" color="#AB2328" />
        </Center.Screen>
      ) : (
        children
      )}
    </View>
  );
}

export default Page;
