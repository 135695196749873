const defaultState = {
  loginInput: {
    email: "",
    password: "",
  },
  user: {
    email: "",
    username: "",
  },
  azureUser: {
    email: "",
    username: "",
  },
};

const state = {
  auth: defaultState,
};

export { state as default, defaultState };
