import { axios } from ".";

const expenseAxios = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_ADMIN_JWT}`,
  },
});

async function getExpensesAPICall(expenseDays) {
  return expenseAxios({
    method: "get",
    url: `/api/v1/expenses/${expenseDays}`,
  });
}

export { expenseAxios, getExpensesAPICall };
