import { thunk } from "easy-peasy";
import { getInvoicesAPICall } from "../../api/invoice";

const thunks = {
  getInvoicesThunk: thunk(async (actions, {}, helpers) => {
    actions.setLoadingAction(true);

    try {
      const invoices = await getInvoicesAPICall(
        helpers.getState().invoice.invoiceNo || null,
        helpers.getState().invoice.invoiceDays || null
      );
      if (invoices.data.length) {
        console.log("got em");
        // playSuccessSound();
      }
    } catch (err) {
      console.log("failed");
      actions.setAlertThunk({
        type: "error",
        message: "Failed to get invoices",
      });
      //playFailSound();
    }

    actions.setLoadingAction(false);
  }),
};

export default thunks;
