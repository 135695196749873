import { thunk } from "easy-peasy";
import { getAuthUserAPICall, loginAPICall } from "../../api/auth";
import { removeAuthToken, setAuthToken } from "../../utilities/api";
import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

const thunks = {
  loginThunk: thunk(async (actions, input) => {
    actions.setLoadingAction(true);

    try {
      const response = await loginAPICall(input);
      const { token, ...user } = response.data;
      await setAuthToken(token);
      actions.setUserAction(user);
      const loginResponse = await msalInstance.loginPopup();
      actions.setAzureUserAction({
        username: loginResponse.account.name,
        email: loginResponse.account.username,
      });
    } catch (err) {
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  logoutThunk: thunk(async (actions, navigation) => {
    await removeAuthToken();
    actions.resetUserAction();
    navigation && navigation.closeDrawer();
  }),
  getAuthUserThunk: thunk(async (actions, navigation) => {
    actions.setLoadingAction(true);

    try {
      const response = await getAuthUserAPICall();
      actions.setUserAction(response.data);

      const loginResponse = await msalInstance.loginPopup();
      actions.setAzureUserAction({
        username: loginResponse.account.name,
        email: loginResponse.account.username,
      });
    } catch (err) {
      console.log(err);
    }

    actions.setLoadingAction(false);
  }),
};

export default thunks;
