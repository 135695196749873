import { action } from "easy-peasy";

const actions = {
  setExpenseAction: action((state, expense) => {
    state.expense = {
      ...state.expense,
      ...expense,
    };
  }),
};

export default actions;
