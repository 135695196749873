import Button from "../../presentational/Button";
import GetExpensesButton from "./GetExpensesButton";
import GetInvoicesButton from "./GetInvoicesButton";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";

// Auth Buttons
Button.Login = LoginButton;
Button.Logout = LogoutButton;

// Invoice / Expense Buttons
Button.GetInvoices = GetInvoicesButton;
Button.GetExpenses = GetExpensesButton;

export default Button;
