import { axios } from ".";

const invoiceAxios = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_ADMIN_JWT}`,
  },
});

// Employee Endpoints
async function getInvoicesAPICall(invoiceNo, invoiceDays) {
  return invoiceAxios({
    method: "get",
    url: invoiceNo
      ? `/api/v1/invoices/${invoiceNo}`
      : `/api/v1/invoices/days/${invoiceDays}`,
  });
}

export { invoiceAxios, getInvoicesAPICall };
