import React from "react";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { NavigationContainer } from "@react-navigation/native";
import Home from "../pages/Home";
import DrawerC from "../containers/Drawer";
import Image from "../presentational/Image";

const Drawer = createDrawerNavigator();

export default function AppDrawerNavigator() {
  return (
    <NavigationContainer>
      <Drawer.Navigator
        screenOptions={{
          headerShown: true,
          headerRight: () => <DrawerC.Header />,
        }}
      >
        <Drawer.Screen
          name="Home"
          component={Home}
          options={{
            title: "CFBS Admin",
          }}
        />
      </Drawer.Navigator>
    </NavigationContainer>
  );
}
