import { useStoreActions, useStoreState } from "easy-peasy";
import Page from "./Page";
import styled from "styled-components/native";
import Input from "../../presentational/Input";
import Center from "../../presentational/Center";
import Card from "../../presentational/Card";
import Button from "../Button";
import Gap from "../../presentational/Gap";
import {
  MaterialCommunityIcons,
  FontAwesome5,
} from "react-native-vector-icons";
import Text from "../../presentational/Text";
import Icon from "../../presentational/Icon";
import Image from "../../presentational/Image";
import { useIsFocused } from "@react-navigation/native";
import { useEffect } from "react";

const SCard = styled(Card)`
  max-width: 600px;
`;

const Header = styled(Text)`
  font-size: 32px;
  text-align: center;
`;

function LoginPage() {
  const loginInput = useStoreState((state) => state.auth.loginInput);
  const { resetLoginInputAction, setLoginInputAction, loginThunk } =
    useStoreActions((actions) => actions);

  const isFocused = useIsFocused();

  useEffect(() => {
    isFocused && resetLoginInputAction();
  }, [isFocused]);

  function onLogin() {
    return () => loginThunk(loginInput);
  }

  return (
    <Page>
      <Center.Screen>
        <SCard>
          <Image name="favicon.png" height={"6vh"} width="100%" />

          <Gap />

          <Header>Login</Header>

          <Gap />

          <Input
            onChangeText={(email) => setLoginInputAction({ email })}
            value={loginInput.email}
            placeholder="Type App Registration Address"
            inputMode="email"
            leftIcon={<Icon Component={MaterialCommunityIcons} name="email" />}
            onSubmitEditing={onLogin()}
          />

          <Gap />

          <Input
            onChangeText={(password) => setLoginInputAction({ password })}
            value={loginInput.password}
            placeholder="Type password"
            secureTextEntry
            leftIcon={<Icon Component={FontAwesome5} name="lock" />}
            onSubmitEditing={onLogin()}
          />

          <Gap />

          <Button.Login>Login</Button.Login>
        </SCard>
      </Center.Screen>
    </Page>
  );
}

export default LoginPage;
