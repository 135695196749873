import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
// import LottieView from 'lottie-react-native';
import LottieView from 'react-native-web-lottie';
import successAnimation from '../../../assets/lottie/success1.json';
import { Modal } from 'react-native';
import { Overlay } from 'react-native-elements';

export default function FlashNotification({ message }) {
  return (
    <Overlay>
      <View style={styles.container}>
        <LottieView
          source={successAnimation}
          autoPlay
          loop={false}
          speed={2}
          style={styles.animation}
          useNativeDriver={false}
        />
        <Text style={styles.text}>{message}</Text>
      </View>
    </Overlay>
  );
}

const styles = StyleSheet.create({
  container: {
    //     position: 'absolute',
    //     bottom: 0,
    //     left: 0,
    //     right: 0,
    //     height: 100,
    //     backgroundColor: '#2ecc71',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     zIndex: 999,
    //
    alignSelf: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },
  animation: {
    width: 150,
    height: 150,
  },
  text: {
    marginTop: 10,
    fontSize: 16,
    fontWeight: 'bold',
    color: '#FFFFFF',
  },
});
