import { action } from "easy-peasy";

const actions = {
  setInvoiceAction: action((state, invoice) => {
    state.invoice = {
      ...state.invoice,
      ...invoice,
    };
  }),
};

export default actions;
