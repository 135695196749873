import { Platform } from "react-native";
import * as SecureStore from "expo-secure-store";
import { refreshTokenAPICall } from "../api/auth";

const key = "token";

function formatAuthHeader(token, type = "Bearer") {
  return `${type} ${token}`;
}

function axiosInterceptor(axios, store) {
  axios.interceptors.request.use(async (config) => {
    config.headers.Authorization = formatAuthHeader(await getAuthToken());
    return config;
  });

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.config.url.includes("token/refresh")) {
        await removeAuthToken();
        store.actions.resetUserAction();
      } else if (error.response.status === 401) {
        // refresh token
        const newAccessTokenResponse = await refreshTokenAPICall();
        const newAccessToken = newAccessTokenResponse.data.accessToken;
        await setAuthToken(newAccessToken);
        error.config.headers.Authorization = formatAuthHeader(newAccessToken);
        return Promise.resolve(await axios.request(error.config));
      }

      return Promise.reject(error);
    }
  );
}

async function getAuthToken() {
  return Platform.OS === "web"
    ? localStorage.getItem(key)
    : SecureStore.getItemAsync(key);
}

async function setAuthToken(token) {
  if (Platform.OS === "web") {
    localStorage.setItem(key, token);
  } else {
    await SecureStore.setItemAsync(key, token);
  }
}

async function removeAuthToken() {
  if (Platform.OS === "web") {
    localStorage.removeItem(key);
  } else {
    await SecureStore.deleteItemAsync(key);
  }
}

export { formatAuthHeader, setAuthToken, axiosInterceptor, removeAuthToken };
