import styled from "rn-css";
import Text from "../Text";

const DrawerTab = styled(Text)`
  font-size: 1rem;
  padding: 12px;

  &:hover {
    background-color: #ededed;
  }
`;

export default DrawerTab;
