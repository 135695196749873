import { View } from "react-native";
import Drawer from "../../presentational/Drawer";
import Button from "../Button";
import styled from "rn-css";
import { useStoreState } from "easy-peasy";

const Container = styled.View`
  padding: 12px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

function DrawerContent({ state, navigation }) {
  return (
    <View style={{ height: "100%" }}>
      <Container>
        {state.routes.map((route) => (
          <Drawer.Tab
            key={route.key}
            onPress={() => navigation.navigate(route.name)}
          >
            {route.name}
          </Drawer.Tab>
        ))}

        <View style={{ marginTop: "auto" }}>
          <Button.Logout navigation={navigation} />
        </View>
      </Container>
    </View>
  );
}

export default DrawerContent;
